.layer-switcher {
  font: 14px Rajdhani, Arial, sans-serif;
  top: 80px;
  right: 8px;
}

.layer-switcher li.layer {
  height: 3em;
  display: block;
}

.layer-switcher li.layer label {
  display: block;
}

.layer-switcher li.layer input.opacity {
  width: 100px;
  margin: 5px 20px;
}
