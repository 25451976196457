.ol-overlay-container .ol-magnify {
  border: 3px solid #369;
  height: 30vh;
  width: 30vw;
  overflow: hidden;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
  background-color: #eee;
}

/*  These two hide the google map links at the bottom of
    the map, but only within the magnify component. */
.ol-magnify .gm-style-cc {
  display: none !important;
}
.ol-magnify .gm-style a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}
